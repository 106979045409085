<template>
  <div>
    <b-button
        v-if="!edit"
        @click="$bvModal.show('modal-add-custom-font-group-'+group.group_id)"
        class="green-button-transparent border-0"
    >
      {{ $t('buttons.addCustomFont') }}
    </b-button>
    <b-modal
        :id="edit ? 'modal-edit-custom-font-group-' + group.group_id + '-' + tableIndex
          : 'modal-add-custom-font-group-' + group.group_id"
       :ref="edit ? 'modal-edit-custom-font-group-' + group.group_id + '-' + tableIndex
          : 'modal-add-custom-font-group-' + group.group_id"
        size="lg"
        footer-class="flex-nowrap"
        centered
    >
      <template #modal-header>
        <h3>{{ edit ? $t('modals.editCustomFont.title') : $t('modals.addCustomFont.title') }}</h3>
      </template>

      <template #default>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col>
            <image-upload
                :acceptedFileTypes="'font/woff,font/woff2'"
                @upload-image-object="setFontFile"
            />
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col cols="4">
            <label class="mb-2" v-html="$t('modals.addCustomFont.fonts')"></label>
          </b-col>
          <b-col>
            <span v-if="!woffFile && !woff2File">
              {{ $t('modals.addCustomFont.noUploadedFiles') }}
            </span>
            <b-row v-if="woffFile" class="ml-0 align-items-center">
              {{ decodeURIComponent(woffFile.url).split('/').pop().split('?')[0] }}
              <b-icon
                icon="trash"
                style="color: #b9b9b9;"
                class="ml-2 pointer" aria-hidden="true"
                @click="removeWoffFile">
              </b-icon>
            </b-row>
            <b-row v-if="woff2File" class="ml-0 align-items-center">
              {{ decodeURIComponent(woff2File.url).split('/').pop().split('?')[0] }}
              <b-icon
                  icon="trash"
                  style="color: #b9b9b9;"
                  class="ml-2 pointer" aria-hidden="true"
                  @click="removeWoff2File">
              </b-icon>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col cols="4">
            <label class="mb-2" v-html="$t('modals.addCustomFont.family')"></label>
          </b-col>
          <b-col>
            <b-form-input
                v-model="fontFamilyName"
                :state="(fontFamilyName === '') ? null : isValidInput(fontFamilyName)"
                aria-describedby="input-live-feedback-1 input-live-feedback-2"
                autofocus
                debounce="500"
            >
            </b-form-input>
            <b-form-invalid-feedback
                v-if="!isValidInput(fontFamilyName)" id="input-live-feedback-2"
            >
              {{ $t('modals.addCustomFont.invalidInput2') }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col cols="4">
            <label class="mb-2" v-html="$t('modals.addCustomFont.weight')"></label>
          </b-col>
          <b-col>
            <b-dropdown
                menu-class="w-100"
                block
                id="setWeight"
                v-model="chosenFontWeight"
                :text="chosenFontWeight"
                autofocus
                debounce="500"
            >
              <div v-for="fw in fontWeights" :key="fw" :value="fw">
                <b-dropdown-item-button @click="setWeight(fw)">
                  {{ fw }}</b-dropdown-item-button>
              </div>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col cols="4">
            <label class="mb-2" v-html="$t('modals.addCustomFont.style')"></label>
          </b-col>
          <b-col>
            <b-form-group v-slot="{ ariaDescribedbyShape }">
              <b-form-radio-group
                  id="radio-shape-options"
                  v-model="chosenStyle"
                  :options="selectedFontStyleOptions"
                  :aria-describedby="ariaDescribedbyShape"
                  name="radio-shape-options"
                  @change="setStyle($event)"
              >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col cols="4">
            <label class="mb-2" v-html="$t('modals.addCustomFont.display')"></label>
          </b-col>
          <b-col>
            <b-dropdown
                menu-class="w-100"
                block
                id="setDisplay"
                v-model="chosenFontDisplay"
                :text="chosenFontDisplay"
                autofocus
                debounce="500"
            >
              <div v-for="fd in fontDisplays" :key="fd" :value="fd">
                <b-dropdown-item-button @click="setDisplay(fd)">
                  {{ fd }}</b-dropdown-item-button>
              </div>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col cols="4">
            <label class="mb-2" v-html="$t('modals.addCustomFont.fallback')"></label>
          </b-col>
          <b-col>
            <b-dropdown
                menu-class="w-100"
                block
                id="setFallback"
                v-model="chosenFallbackFont"
                :text="chosenFallbackFont"
                autofocus
                debounce="500"
            >
              <div v-for="ff in fallbackFonts" :key="ff" :value="ff">
                <b-dropdown-item-button @click="setFallback(ff)">
                  {{ ff }}</b-dropdown-item-button>
              </div>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="mt-4 mx-5 mb-4">
          <b-col>
            <span> {{ $t('modals.addCustomFont.text') }} </span>
          </b-col>
        </b-row>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            :disabled="(edit ?
              (!fileUploaded || !hasBeenEdited() || !isValidInput(fontFamilyName)) :
              (!fileUploaded || fontFamilyName === '' || !isValidInput(fontFamilyName)))"
            variant="primary"
            class="p-3 disabled-cursor"
            block
            @click="(edit ? editCustomFont() : addCustomFont())"
        >
          {{ edit ? $t('modals.editCustomFont.validate') : $t('modals.addCustomFont.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'ModalCreateAndEditCustomFonts',
  components: {
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
  },
  props: ['group', 'customFont', 'tableIndex', 'edit'],
  data() {
    return {
      woffFile: this.edit ? this.retrieveWoffFile() : null,
      woff2File: this.edit ? this.retrieveWoff2File() : null,
      fileUploaded: this.edit,
      fontFamilyName: this.edit ? this.customFont.fontFamily : '',
      chosenFontWeight: this.edit ? this.customFont.fontWeight : '400',
      fontWeights: [
        '100',
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
      ],
      chosenStyle: this.edit ? this.customFont.style : 'normal',
      selectedFontStyleOptions: [
        { text: 'Normal', value: 'normal' },
        { text: 'Italic', value: 'italic' },
      ],
      chosenFontDisplay: this.edit ? this.customFont.fontDisplay : 'Swap',
      fontDisplays: [
        'auto',
        'block',
        'swap',
        'fallback',
        'pptional',
      ],
      chosenFallbackFont: this.edit ? this.customFont.fallbackFont : 'Verdana',
      fallbackFonts: [
        'Arial',
        'Arial Black',
        'Verdana',
        'Tahoma',
        'Trebuchet MS',
        'Impact',
        'Times New Roman',
        'Didot',
        'Georgia',
        'American Typewriter',
        'Andalé Mono',
        'Courier',
        'Lucida Console',
        'Monaco',
        'Bradley Hand',
        'Brush Script MT',
        'Luminari',
        'Comic Sans MS',
      ],
    };
  },
  methods: {
    retrieveWoffFile() {
      for (let i = 0; i < this.customFont.fontFiles.length; i++) {
        if (this.customFont.fontFiles[i].filetype === 'font/woff') {
          return this.customFont.fontFiles[i];
        }
      }
      return null;
    },
    retrieveWoff2File() {
      for (let i = 0; i < this.customFont.fontFiles.length; i++) {
        if (this.customFont.fontFiles[i].filetype === 'font/woff2') {
          return this.customFont.fontFiles[i];
        }
      }
      return null;
    },
    isValidInput(inputString) {
      const regex = /^[0-9A-Za-z_ -]*$/g;
      return regex.test(inputString) && inputString === inputString.trim();
    },
    setFontFile(uploadedFontFile) {
      if (uploadedFontFile && uploadedFontFile.filetype === 'font/woff') {
        this.woffFile = {
          url: uploadedFontFile.url,
          filetype: uploadedFontFile.filetype,
        };
        this.fileUploaded = true;
      } else if (uploadedFontFile && uploadedFontFile.filetype === 'font/woff2') {
        this.woff2File = {
          url: uploadedFontFile.url,
          filetype: uploadedFontFile.filetype,
        };
        this.fileUploaded = true;
      } else {
        this.fileUploaded = false;
      }
    },
    removeWoffFile() {
      this.woffFile = null;
      if (!this.woff2File) {
        this.fileUploaded = false;
      }
    },
    removeWoff2File() {
      this.woff2File = null;
      if (!this.woffFile) {
        this.fileUploaded = false;
      }
    },
    setWeight(fw) {
      this.chosenFontWeight = fw;
    },
    setStyle(s) {
      this.chosenStyle = s;
    },
    setDisplay(fd) {
      this.chosenFontDisplay = fd;
    },
    setFallback(ff) {
      this.chosenFallbackFont = ff;
    },
    hasBeenEdited() {
      const tmpWoffUrl = this.retrieveWoffFile();
      const tmpWoff2Url = this.retrieveWoff2File();
      if (
        ((tmpWoffUrl
            && this.woffFile
            && tmpWoffUrl !== this.woffFile)
          || (tmpWoff2Url
            && this.woff2File
            && tmpWoff2Url !== this.woff2File)
          || (tmpWoffUrl && !this.woffFile && this.fileUploaded)
          || (tmpWoff2Url && !this.woff2File && this.fileUploaded)
          || (!tmpWoffUrl && this.woffFile)
          || (!tmpWoff2Url && this.woff2File)
          || this.customFont.fontFamily !== this.fontFamilyName
          || this.customFont.fontWeight !== this.chosenFontWeight
          || this.customFont.style !== this.chosenStyle
          || this.customFont.fontDisplay !== this.chosenFontDisplay
          || this.customFont.fallbackFont !== this.chosenFallbackFont)
        && this.fontFamilyName !== ''
        && this.isValidInput(this.fontFamilyName)) {
        return true;
      }
      return false;
    },
    closeModal() {
      this.resetForm();
      const modalRef = this.edit ? `modal-edit-custom-font-group-${this.group.group_id}-${this.tableIndex}`
        : `modal-add-custom-font-group-${this.group.group_id}`;
      this.$refs[modalRef].hide();
    },
    addCustomFont() {
      const newFont = {
        fontFiles: [],
        fontFamily: this.fontFamilyName,
        fontWeight: this.chosenFontWeight,
        style: this.chosenStyle,
        fontDisplay: this.chosenFontDisplay,
        fallbackFont: this.chosenFallbackFont,
      };
      if (this.woffFile) {
        newFont.fontFiles.push({
          filetype: this.woffFile.filetype,
          url: this.woffFile.url,
        });
      }
      if (this.woff2File) {
        newFont.fontFiles.push({
          filetype: this.woff2File.filetype,
          url: this.woff2File.url,
        });
      }
      this.resetForm();
      this.$emit('addCustomFont', newFont);
      const modalRef = `modal-add-custom-font-group-${this.group.group_id}`;
      this.$refs[modalRef].hide();
    },
    editCustomFont() {
      const newFont = {
        fontFiles: [],
        fontFamily: this.fontFamilyName,
        fontWeight: this.chosenFontWeight,
        style: this.chosenStyle,
        fontDisplay: this.chosenFontDisplay,
        fallbackFont: this.chosenFallbackFont,
      };
      if (this.woffFile) {
        newFont.fontFiles.push({
          filetype: this.woffFile.filetype,
          url: this.woffFile.url,
        });
      }
      if (this.woff2File) {
        newFont.fontFiles.push({
          filetype: this.woff2File.filetype,
          url: this.woff2File.url,
        });
      }
      this.$emit('editCustomFont', newFont, this.tableIndex);
      const modalRef = `modal-edit-custom-font-group-${this.group.group_id}-${this.tableIndex}`;
      this.$refs[modalRef].hide();
    },
    resetForm() {
      if (this.edit) {
        for (let i = 0; i < this.customFont.fontFiles.length; i++) {
          if (this.customFont.fontFiles[i].filetype === 'font/woff') {
            this.woffFile = this.customFont.fontFiles[i];
          } else if (this.customFont.fontFiles[i].filetype === 'font/woff2') {
            this.woff2File = this.customFont.fontFiles[i];
          }
        }
      } else {
        this.woffFile = null;
        this.woff2File = null;
      }
      this.fileUploaded = this.edit;
      this.fontFamilyName = this.edit ? this.customFont.fontFamily : '';
      this.chosenFontWeight = this.edit ? this.customFont.fontWeight : '400';
      this.chosenStyle = this.edit ? this.customFont.style : 'normal';
      this.chosenFontDisplay = this.edit ? this.customFont.fontDisplay : 'Swap';
      this.chosenFallbackFont = this.edit ? this.customFont.fallbackFont : 'Verdana';
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.disabled-cursor:disabled {
  cursor: not-allowed;
}
</style>
