var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.edit
        ? _c(
            "b-button",
            {
              staticClass: "green-button-transparent border-0",
              on: {
                click: function($event) {
                  return _vm.$bvModal.show(
                    "modal-add-custom-font-group-" + _vm.group.group_id
                  )
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("buttons.addCustomFont")) + " ")]
          )
        : _vm._e(),
      _c("b-modal", {
        ref: _vm.edit
          ? "modal-edit-custom-font-group-" +
            _vm.group.group_id +
            "-" +
            _vm.tableIndex
          : "modal-add-custom-font-group-" + _vm.group.group_id,
        attrs: {
          id: _vm.edit
            ? "modal-edit-custom-font-group-" +
              _vm.group.group_id +
              "-" +
              _vm.tableIndex
            : "modal-add-custom-font-group-" + _vm.group.group_id,
          size: "lg",
          "footer-class": "flex-nowrap",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.edit
                        ? _vm.$t("modals.editCustomFont.title")
                        : _vm.$t("modals.addCustomFont.title")
                    )
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c(
                      "b-col",
                      [
                        _c("image-upload", {
                          attrs: { acceptedFileTypes: "font/woff,font/woff2" },
                          on: { "upload-image-object": _vm.setFontFile }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c("label", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("modals.addCustomFont.fonts")
                          )
                        }
                      })
                    ]),
                    _c(
                      "b-col",
                      [
                        !_vm.woffFile && !_vm.woff2File
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "modals.addCustomFont.noUploadedFiles"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        _vm.woffFile
                          ? _c(
                              "b-row",
                              { staticClass: "ml-0 align-items-center" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      decodeURIComponent(_vm.woffFile.url)
                                        .split("/")
                                        .pop()
                                        .split("?")[0]
                                    ) +
                                    " "
                                ),
                                _c("b-icon", {
                                  staticClass: "ml-2 pointer",
                                  staticStyle: { color: "#b9b9b9" },
                                  attrs: {
                                    icon: "trash",
                                    "aria-hidden": "true"
                                  },
                                  on: { click: _vm.removeWoffFile }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.woff2File
                          ? _c(
                              "b-row",
                              { staticClass: "ml-0 align-items-center" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      decodeURIComponent(_vm.woff2File.url)
                                        .split("/")
                                        .pop()
                                        .split("?")[0]
                                    ) +
                                    " "
                                ),
                                _c("b-icon", {
                                  staticClass: "ml-2 pointer",
                                  staticStyle: { color: "#b9b9b9" },
                                  attrs: {
                                    icon: "trash",
                                    "aria-hidden": "true"
                                  },
                                  on: { click: _vm.removeWoff2File }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c("label", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("modals.addCustomFont.family")
                          )
                        }
                      })
                    ]),
                    _c(
                      "b-col",
                      [
                        _c("b-form-input", {
                          attrs: {
                            state:
                              _vm.fontFamilyName === ""
                                ? null
                                : _vm.isValidInput(_vm.fontFamilyName),
                            "aria-describedby":
                              "input-live-feedback-1 input-live-feedback-2",
                            autofocus: "",
                            debounce: "500"
                          },
                          model: {
                            value: _vm.fontFamilyName,
                            callback: function($$v) {
                              _vm.fontFamilyName = $$v
                            },
                            expression: "fontFamilyName"
                          }
                        }),
                        !_vm.isValidInput(_vm.fontFamilyName)
                          ? _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "input-live-feedback-2" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "modals.addCustomFont.invalidInput2"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c("label", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("modals.addCustomFont.weight")
                          )
                        }
                      })
                    ]),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              "menu-class": "w-100",
                              block: "",
                              id: "setWeight",
                              text: _vm.chosenFontWeight,
                              autofocus: "",
                              debounce: "500"
                            },
                            model: {
                              value: _vm.chosenFontWeight,
                              callback: function($$v) {
                                _vm.chosenFontWeight = $$v
                              },
                              expression: "chosenFontWeight"
                            }
                          },
                          _vm._l(_vm.fontWeights, function(fw) {
                            return _c(
                              "div",
                              { key: fw, attrs: { value: fw } },
                              [
                                _c(
                                  "b-dropdown-item-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.setWeight(fw)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(fw))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c("label", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("modals.addCustomFont.style")
                          )
                        }
                      })
                    ]),
                    _c(
                      "b-col",
                      [
                        _c("b-form-group", {
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function({ ariaDescribedbyShape }) {
                                return [
                                  _c("b-form-radio-group", {
                                    attrs: {
                                      id: "radio-shape-options",
                                      options: _vm.selectedFontStyleOptions,
                                      "aria-describedby": ariaDescribedbyShape,
                                      name: "radio-shape-options"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.setStyle($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.chosenStyle,
                                      callback: function($$v) {
                                        _vm.chosenStyle = $$v
                                      },
                                      expression: "chosenStyle"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c("label", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("modals.addCustomFont.display")
                          )
                        }
                      })
                    ]),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              "menu-class": "w-100",
                              block: "",
                              id: "setDisplay",
                              text: _vm.chosenFontDisplay,
                              autofocus: "",
                              debounce: "500"
                            },
                            model: {
                              value: _vm.chosenFontDisplay,
                              callback: function($$v) {
                                _vm.chosenFontDisplay = $$v
                              },
                              expression: "chosenFontDisplay"
                            }
                          },
                          _vm._l(_vm.fontDisplays, function(fd) {
                            return _c(
                              "div",
                              { key: fd, attrs: { value: fd } },
                              [
                                _c(
                                  "b-dropdown-item-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.setDisplay(fd)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(fd))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c("label", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("modals.addCustomFont.fallback")
                          )
                        }
                      })
                    ]),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              "menu-class": "w-100",
                              block: "",
                              id: "setFallback",
                              text: _vm.chosenFallbackFont,
                              autofocus: "",
                              debounce: "500"
                            },
                            model: {
                              value: _vm.chosenFallbackFont,
                              callback: function($$v) {
                                _vm.chosenFallbackFont = $$v
                              },
                              expression: "chosenFallbackFont"
                            }
                          },
                          _vm._l(_vm.fallbackFonts, function(ff) {
                            return _c(
                              "div",
                              { key: ff, attrs: { value: ff } },
                              [
                                _c(
                                  "b-dropdown-item-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.setFallback(ff)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(ff))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-col", [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("modals.addCustomFont.text")) +
                            " "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 disabled-cursor",
                    attrs: {
                      disabled: _vm.edit
                        ? !_vm.fileUploaded ||
                          !_vm.hasBeenEdited() ||
                          !_vm.isValidInput(_vm.fontFamilyName)
                        : !_vm.fileUploaded ||
                          _vm.fontFamilyName === "" ||
                          !_vm.isValidInput(_vm.fontFamilyName),
                      variant: "primary",
                      block: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.edit ? _vm.editCustomFont() : _vm.addCustomFont()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.edit
                            ? _vm.$t("modals.editCustomFont.validate")
                            : _vm.$t("modals.addCustomFont.validate")
                        ) +
                        " "
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }